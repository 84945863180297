<template>
  <div>
    <div class="detailContent">
      <!-- 应用信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Threatening.AppInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationName')}}：</span>
            <span class="detaildesc">{{ detailData.name }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.ApplicationVersion')}}：</span>
            <span class="detaildesc">{{ detailData.version }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.SdkVersion')}}：</span>
            <span class="detaildesc">{{ detailData.sdkVersion }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.AppPackageName')}}：</span>
            <span class="detaildesc">{{ detailData.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{ $t('SecurityMonitor.Threatening.AppMD5')}}：</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.appMd5 }}</span>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Threatening.BasicInformation')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{ $t('public.DeviceName')}}：</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.devicename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.Name')}}：</span>
            <span class="detaildesc">{{ detailData.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.EquipmentModel')}}：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel">设备厂商：</span>
            <span class="detaildesc"></span>
          </div>
          <div class="group">
            <span class="detailLabel">合规状态：</span>
            <span class="detaildesc"></span>
          </div> -->
          <div class="group">
            <span class="detailLabel">{{ $t('public.SystemVersion')}}：</span>
            <span class="detaildesc">{{ detailData.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.DeviceIP')}}：</span>
            <span class="detaildesc">{{ detailData.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('public.Username')}}：</span>
            <span class="detaildesc">{{ detailData.loginname }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{ $t('SecurityMonitor.Threatening.UserDepartment')}}：</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </div>
          <!-- <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div> -->
          <!-- <div class="group">
            <span class="detailLabel">设备越狱：</span>
            <span class="detaildesc"></span>
          </div> -->
          <div class="group">
            <!-- <span class="detailLabel" style="display: block; float: left; ">{{ $t('SecurityMonitor.Threatening.EquipmentIMEI')}}：</span> -->
                        <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
                        <span v-if="$common.getSystemName(detailData.osVersion)=='Android'" class="detailLabel"  style="display: block; float: left; "
                        >{{ $t("public.IMEIAndroid") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='iOS'" class="detailLabel"  style="display: block; float: left; "
                        >{{ $t("public.IMEIiOS") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='Linux'" class="detailLabel"  style="display: block; float: left; "
                        >{{ $t("public.IMEIMac") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='Windows'" class="detailLabel"  style="display: block; float: left; "
                        >{{ $t("public.IMEIMac") }}：</span
                      >
                      <span v-if="$common.getSystemName(detailData.osVersion)=='Unknown'" class="detailLabel"  style="display: block; float: left; "
                        >{{ $t("public.IMEIUnknown") }}：</span
                      >
            <span class="detaildesc" >{{ detailData.imei
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; ">{{ $t('public.InternetMethod')}}：</span>
            <span class="detaildesc" >{{  $filter.surfInterType(detailData.netType) }}</span>
          </div>
        </div>
      </div>
      <!-- 位置信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Threatening.PositionInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.Country')}}：</span>
            <span class="detaildesc">{{ detailData.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.City')}}：</span>
            <span class="detaildesc">{{ detailData.city }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.Province')}}：</span>
            <span class="detaildesc">{{ detailData.province }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.LatitudeLongitude')}}：</span>
            <span class="detaildesc" v-if="detailData.longitude == ''"></span>
            <span class="detaildesc" v-else>{{ detailData.longitude }},{{ detailData.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.Address')}}：</span>
          <span class="detaildesc">{{ detailData.location }}</span>
        </div>
      </div>
      <!-- 风险信息 -->
      <p class="detailTitle">{{ $t('SecurityMonitor.Threatening.RiskInfo')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{ $t('public.BehaviorType')}}：</span>
            <span class="detaildesc">{{ detailData.type }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.GenerationTime')}}：</span>
            <span class="detaildesc">{{
            detailData.time
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; width: 28%">{{ $t('SecurityMonitor.Threatening.ResponseAction') }}：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.rule
            }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{ $t('SecurityMonitor.Threatening.BehaviorLevel') }}：</span>
            <span class="detaildesc" v-if="detailData.level == '3'" style="color: #d9001b; cursor: auto">{{ $t('SecurityMonitor.Threatening.Tall') }}</span>
            <span class="detaildesc" v-if="detailData.level == '2'" style="color: #f59a23; cursor: auto">{{ $t('SecurityMonitor.Threatening.Centre') }}</span>
            <span class="detaildesc" v-if="detailData.level == '1'" style="color: #00cdcd; cursor: auto">{{ $t('SecurityMonitor.Threatening.Low') }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{ $t('public.ReportingTime')}}：</span>
            <span class="detaildesc">{{
            detailData.createtime
            }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div class="detailLabel" style="float: left;
                height: 24px;
                line-height: 24px;
                text-align: left;
                pading-right: 5px;
                width:13%;
              ">
            <span>{{ $t('SecurityMonitor.Threatening.JudgmentSteps') }}：</span>
          </div>

          <!-- <p v-if="detailData.type == '锁屏密码未开启'|| detailData.type == '系统时间异常' ||detailData.type == '截屏阻断解除'">
            <span v-if="detailData.type == '锁屏密码未开启'">1)</span>
            <span v-if="detailData.type == '系统时间异常'">2)</span>
            <span v-if="detailData.type == '截屏阻断解除'">3)</span>
            {{
          detailData.type }}</p> -->
          <div style="
                float: left;
                width: 78%;
                height: inherit;
                text-align: left;
                padding-right: 5px;
              " class="detaildesc">
            <p v-for="(e, i) in detailData.decideStr" :key="i" style="
                  width: 100%;
                  display: inline-block;
                  overflow: hidden;
                  color: #606266;
                  margin-left: 0;
                  line-height: 24px;
                  word-wrap: break-word;
                  word-break: break-all;
                  color:#606266
                ">
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'detail',

  props: {
    detailData: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      groupFullNameShowByIndex: '',
    }
  },

  methods: {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  }
}
</script>

<style lang="scss" scoped>
.detailContent {
  width: 100%;

  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }

  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;

    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 44%;
          text-align: left;
        }

        .detaildesc {
          width: 56%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }

    .boxRight {
      width: 50%;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 23%;
          text-align: left;
        }

        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }

  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;

    .group {
      margin-top: 8px;

      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }

      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}

.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
</style>
